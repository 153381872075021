import { Injectable } from '@angular/core';
import { BaseService } from "./base-service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService extends BaseService {
  private cacheTranslations: any = null;
  constructor(private httpClient: HttpClient) {
    super();
  }

  get(locale?: string, hToken?: string, tenantId?: string, brandExternalId?: string): Observable<any> {
    const getOrderAndConfigUrl = this.serverUrl + "api/v2/translations/content/hosted-tracking";
    let params = new HttpParams();
    let headers = new HttpHeaders();
    if (locale) {
      params = params.set('preferredLocale', locale);
    }
    if (hToken) {
      headers = headers.set("h-token", hToken);
    }
    console.log('ts tenantId: ', tenantId);
    if (tenantId) {
      headers = headers.set("tenantid", tenantId);
    }
    if (tenantId && brandExternalId) {
      headers = headers.set("h-json", JSON.stringify({
        tenantId,
        brandExternalId
      }));
    }
    console.log('headers: ', headers);
    return this.httpClient.get(getOrderAndConfigUrl, { params, headers }).pipe(
      tap(translations => {
        console.log('fetched translations: ', translations);
        this.cacheTranslations = translations
      }), catchError(e => {
        console.log('error in fetching translation', e);
        return of(undefined)
      })
    );
  }

  clearCache(): void {
    this.cacheTranslations = null;
  }
}
